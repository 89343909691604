.terms {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.terms-header {
  text-align: center;
  margin-bottom: 20px;
}

.terms-content {
  font-size: 16px;
  line-height: 1.6;
}

.terms-content ul {
  list-style-type: disc;
  padding-left: 20px;
}
