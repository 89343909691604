body {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.welcome-page {
  text-align: center;
  padding: 20px;
}

.simple-header {
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.2
  ); /* Semi-transparent white background */
  color: black;
  top: 0;
  backdrop-filter: blur(3px);
}

.header-left {
  font-size: 1em;
  font-weight: bold;
}

.header-right {
  position: relative;
  font-weight: normal;
  padding-right: 60px;
}

.menu-button {
  background: none;
  border: none;
  color: black;
  font-size: 2em;
  cursor: pointer;
}

.menu {
  display: block; /* Use block or inline-block depending on your layout needs */
  position: absolute;
  top: 100%;
  right: 30px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  padding: 10px; /* Add some padding for better spacing */
}

.menu-link {
  display: block;
  padding: 10px 20px;
  color: #4a90e2;
  text-decoration: none;
  white-space: nowrap;
  margin: 10px 0;
}

.menu-link:hover {
  background-color: #e9f4ff;
}

.welcome-header {
  background-color: #4a90e2;
  color: white;
  padding: 60px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.welcome-header h1 {
  font-size: 3em;
  margin: 0;
}

.welcome-header p {
  font-size: 1.5em;
  margin: 10px 0 0;
}

.features-section,
.how-it-works-section,
.get-started-section {
  background-color: white;
  margin: 20px auto;
  padding: 20px;
  max-width: 800px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.features-section h2,
.how-it-works-section h2,
.get-started-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333;
}

.features-section ul {
  list-style-type: none;
  padding: 0;
}

.features-section li {
  font-size: 1.2em;
  margin: 10px 0;
  text-align: left;
}

.features-section li strong {
  color: #4a90e2;
}

.how-it-works-steps {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.how-it-works-steps .step {
  background-color: #e9f4ff;
  padding: 20px;
  border-radius: 8px;
}

.how-it-works-steps .step h3 {
  margin-top: 0;
  color: #4a90e2;
}

.how-it-works-steps .step p {
  font-size: 1.1em;
  color: #555;
}

.get-started-section {
  background-color: #4a90e2;
  color: white;
  padding: 40px 20px;
  border-radius: 8px;
}

.get-started-section p {
  font-size: 1.3em;
  margin: 20px 0;
}

.buttons {
  margin: 20px 0;
}

.button {
  display: inline-block;
  padding: 15px 25px;
  margin: 10px;
  font-size: 1.2em;
  color: white;
  background-color: #333;
  border-radius: 8px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.button.signup-button {
  background-color: #28a745;
}

.button.signin-button {
  background-color: #17a2b8;
}

.button:hover {
  background-color: #555;
}

.welcome-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 10px;
  position: fixed;
  width: 100%;
  bottom: 0;
  text-align: center;
}

.footer-left {
  font-weight: normal;
}

.footer-right {
  position: relative;
  font-weight: normal;
  padding-right: 60px;
}

.footer-link {
  margin: 0 10px;
  text-decoration: none;
  color: #007bff;
}

.footer-link:hover {
  text-decoration: underline;
}

.footer-spacer {
  flex: 1;
}

.footer-copyright {
  margin-left: 20px;
}
