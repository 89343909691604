.modal {
  &__backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    /* Ensure modal is on top */
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__wrapper {
    position: relative;
    border: 1px solid black;
    background: white;
    width: 400px;
    max-width: 90%;
    /* Ensure modal is not wider than the viewport */
    max-height: 80vh;
    /* Ensure modal is not taller than the viewport */
    overflow-y: auto;
    /* Enable scrolling for content taller than modal */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__close {
    cursor: pointer;
    font-size: 24px;
    color: #999;
    transition: color 0.3s;

    &:hover {
      color: red;
    }
  }

  &__content {
    padding: 20px;
  }
}
