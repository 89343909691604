.signin-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  font-family: "Arial", sans-serif;
}

.signin-form h2 {
  margin-bottom: 30px;
  text-align: center;
  color: #333;
  font-size: 24px;
  font-weight: bold;
}

.signin-form input[type="email"],
.signin-form input[type="password"] {
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  font-size: 16px;
}

.signin-form button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  font-size: 16px;
}

.signin-form button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.signin-form .google-signin-button,
.signin-form .anonymous-signin-button {
  margin-top: 10px;
  background-color: white;
  color: #757575;
  border: 1px solid #dadce0;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.signin-form .google-signin-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.signin-form .google-signin-button:hover,
.signin-form .anonymous-signin-button:hover {
  background-color: #f8f9fa;
  transform: translateY(-2px);
}

.signin-form .google-signin-text,
.signin-form .anonymous-signin-button-text {
  font-size: 16px;
  color: #757575;
}

.signin-form .text-sm {
  margin-top: 20px;
  text-align: center;
  color: #333;
  font-size: 14px;
}

.signin-form .text-sm a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.signin-form .text-sm a:hover {
  color: #0056b3;
}

.forgot-password-link {
  display: block;
  margin-bottom: 20px;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.forgot-password-link:hover {
  color: #0056b3;
}
