.parse-file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ccc;
  height: 100%;
}

.file-form-section {
  width: 100%;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
}

.output-section {
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;
  display: flex;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.output-section::-webkit-scrollbar {
  width: 10px;
}

.output-section::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.output-section::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.parse-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  width: 50%;
  height: 40px;
}

.parse-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 100%;
  width: 100%;
  padding: 0;
  font-family: "Courier New", Courier, monospace;
  font-size: medium;
}

.parse-button:hover {
  background-color: #0056b3;
}
