.signup-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.signup-form h2 {
  margin-bottom: 30px;
  text-align: center;
  color: #333;
}

.signup-form input[type="email"],
.signup-form input[type="password"] {
  width: 100%;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
}

.signup-form button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-form button:hover {
  background-color: #0056b3;
}

.signup-form .text-sm {
  margin-top: 20px;
  text-align: center;
  color: #333;
}

.signup-form .text-sm a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.signup-form .text-sm a:hover {
  color: #0056b3;
}
