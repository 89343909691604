.file-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  width: 50%;
  height: 40px;
}

.file-button {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  height: 100%;
  align-items: center;
  font-family: "Courier New", Courier, monospace;
  font-size: medium;
}

.file-button:hover {
  background-color: #0056b3;
}

.info-box {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 10px;
  margin-top: 10px;
}

.info-box p {
  margin: 0;
}
