/* Reset box model for all elements */
* {
  box-sizing: border-box;
}

.grid {
  position: relative;
  border: 1px solid #ccc;
  background: #f9f9f9;
  margin-left: 0;
  margin-bottom: 20px;
  overflow-y: auto;
  max-height: 500px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #ccc;
  background: #fff;
  color: #333;
  padding: 10px;
}

th {
  background: #f0f0f0;
  color: #000;
  position: sticky;
  top: 0;
}
