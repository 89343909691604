.title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.title {
  font-size: 24px;
  margin: 0;
}

.user-menu-container {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.user-account-icon {
  background-color: red;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-account-icon:hover {
  opacity: 0.8;
}
