:root {
  --title-bar-height: 60px;
  --console-log-box-height: 100px;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.content {
  display: flex;
  flex: 1;
  margin-top: var(--title-bar-height);
  margin-bottom: var(--console-log-box-height);
  overflow-y: auto;
}

.left-half,
.right-half {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
}

.left-half textarea {
  flex: 1;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
