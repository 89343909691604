* {
  box-sizing: border-box;
}

/* General styles */
.edit-profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.edit-profile {
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Button styles */
.edit-profile button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin: 10px 0;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.update-button {
  background-color: #4caf50;
  color: white;
}

.update-button:hover {
  background-color: #388e3c;
}

.delete-button {
  background-color: #f44336;
  color: white;
}

.delete-button:hover {
  background-color: #d32f2f;
}

.cancel-button {
  background-color: #9e9e9e;
  color: white;
}

.cancel-button:hover {
  background-color: #757575;
}

/* Input styles */
.edit-profile input {
  display: block;
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

/* Placeholder text styles */
.edit-profile input::placeholder {
  color: #999; /* Adjust placeholder text color as needed */
}

/* Input focus styles */
.edit-profile input:focus {
  border-color: #4caf50; /* Adjust focus border color as needed */
  outline: none; /* Remove default outline */
}

/* Input hover styles */
.edit-profile input:hover {
  border-color: #999; /* Adjust hover border color as needed */
}

/* Message styles */
.message {
  margin-top: 10px;
  color: red;
  text-align: center;
}
