.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.profile-heading {
  margin-bottom: 20px;
  font-size: 24px;
}

.profile-info {
  width: 100%;
  max-width: 400px;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.profile-info img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.profile-text {
  margin: 10px 0;
}

.profile-note {
  margin-bottom: 10px;
}

.convert-section {
  margin-top: 20px;
}

.convert-input {
  width: calc(50% - 5px);
  margin-right: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.convert-button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 16px;
  background-color: #4a90e2;
  color: white;
}

.convert-button:hover {
  background-color: #357ab8;
}

.profile-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.profile-button {
  width: calc(33.33% - 5px);
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.profile-button:hover {
  background-color: #357ab8;
  color: white;
}
