.user-menu {
  position: absolute;
  top: 60px;
  right: 60px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-menu li {
  padding: 0;
}

.menu-item {
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  text-align: left;
}

.menu-item:hover {
  background-color: #f2f2f2;
}
